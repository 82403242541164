import React, {Component} from 'react';

import {
	Route,
	Switch
} from "react-router-dom";

import "./stylesheets/site-wide.scss";
import "./stylesheets/media-queries.scss";

import {DEVMODE, LANGUAGES_AND_CURRENCY_CODES} from "./utilities/resources";

import * as Pages from "./components/pages";

import ProjectDropdown from "./components/parts/navbar/MyTree/ProjectDropdown";
import {
	AfterBrand,
	AfterCollapse,
} from "./components/parts/navbar/MyTree";
import {MenuDropdown} from "./components/parts/navbar/Shared";
import {SharedNavbar} from "./components/parts/SharedNavbar";
import {Alerts} from "./components/parts/Alert";
import {DevelopmentWarning} from "./components/parts/GeneralPageElements";
import {Message} from "./components/parts/Message"

import {Database} from "./classes/database"
import {Project} from "./classes/project";
import {Benefits} from "./classes/benefits";
import {Location} from "./classes/location";
import {StorageHandler} from "./classes/storage";

import * as PROPS from "./components/static_props/MyTree";

import {csvExport} from "./utilities/csvExport";
import {csvImport} from "./utilities/csvImport";

import "./components/icons";
import {ProcessingModal} from "./components/parts/Modals";

import {fetchExchangeRates} from "./utilities/utilities";


export class MyTree extends Component {
	constructor(props) {
		super(props);
		this.storageKeys = {
			general: "MyTree_WIP",
			exchangeRate: "xchange",
			dbName: "MyTreeDB",
		};
		this.store = new StorageHandler({key: this.storageKeys.general});

		let state = this.store.getFromStorage();

		// Determine browser language that exists within our list of languages.
		let currencyLocale = "en-US";
		navigator.languages.forEach((language, index) => {
			if (
				Object.keys(LANGUAGES_AND_CURRENCY_CODES).includes(language) &&
				currencyLocale.length <= 0
			) {
				currencyLocale = language;
			}
		});

		this.state = {
			project: new Project(state),
			unitType: true,
			sortSpeciesAlphabetically: true,
			processing: false,
			currencyLocale: currencyLocale,
			projects: [],
			alerts: {
				dashboard: [],
				location: [],
				tree: [],
				building: [],
				results: []
			},
			messages: {
				title: "",
				content: ""
			},
		};

		this.database = {
			available: null,
			checked: false,
			database: null,
			params: {
				name: this.storageKeys.dbName,
				version: 1,
				stores: {
					projects: "uuid",
				},
			},
		};

		this.exchangeRates = {};

		window.onpopstate = () => {
			window.scrollTo({top: 0});
		};
	}

	async componentDidMount() {
		this.retrieveProjects();
		if (Object.keys(this.exchangeRates).length === 0) {
			let exchangeRates = this.store.getFromStorage(
				this.storageKeys.exchangeRate
			);
			if (Object.keys(exchangeRates).length) {
				this.exchangeRates = exchangeRates;
			} else {
				exchangeRates = await fetchExchangeRates();
				this.store.putInStorage(exchangeRates, this.storageKeys.exchangeRate);
				this.exchangeRates = exchangeRates;
			}
		}
	};

	componentWillUnmount() {
		this.store.removeFromStorage(this.storageKeys.exchangeRate);
	}

	// Parameters are an object to allow name-spacing.
	initializeProject = ({callback = null}) => {
		let current_p = {...this.state.project};
		let new_p = new Project();

		let fields = current_p.location.__fields();
		delete fields.uuid;
		new_p.location = new Location(fields);
		new_p.group = current_p.group;

		this.setState({
				project: new_p
			},
			() => {
				this.store.putInStorage(this.state.project);
				if (callback) callback();
			}
		);
	};

	saveProject = async (history, path, submitToEngine = true) => {
		if (submitToEngine) {
			this.setState({
					processing: true,
				},
				async () => {
					let project = {...this.state.project};
					let projects = [...this.state.projects];
					let timeline = "forwards";
					let years = 20;
					let report = "full";
					let benefitType = "cumulative";
					let options = {timeline, years, report, benefitType};
					let data = await Benefits.getBenefits(project, options);

					project.editing = false;

					project.error = "";
					project.benefits.error = "";
					project.futureBenefits.error = "";

					project.warning = "";
					project.benefits.warning = "";
					project.futureBenefits.warning = "";

					if (data.error) {
						project.error = data.error;
						project.benefits.error = data.error;
						project.futureBenefits.error = data.error;
					} else {
						if (data.warnings) {
							let warnings = data.warnings;
							warnings.forEach((warning, index) => {
								if (
									typeof warning !== "undefined" &&
									warning != null &&
									!warning.includes("best matched Location")
								) {
									project.warning = warning.toString();
									project.benefits.warning = warning.toString();
									project.futureBenefits.warning = warning.toString();
								}
							});
						}
						project.benefits.assignBenefits(data.benefits[0]);
						project.futureBenefits.assignBenefits(data.benefits[19]);
						project.benefits.meta = data.meta;
						project.futureBenefits.meta = data.meta;
					}
					// Removes the project being saved from projects to avoid duplication.
					projects = projects.filter(p => p.uuid !== project.uuid);
					// Puts the project back into the filtered projects.
					projects.push(project);

					this.setState({
							processing: false, // FIXME: Not sure if this is the best place to set processing to false.
							project: project,
							projects: projects,
						},
						() => {
							this.store.putInStorage(project);
							if (this.database.available) {
								this.database.database.save(
									"projects",
									project
								).then(() => {
									// if (this.state.projects.length <= 0) this.getAllProjects();
								}).catch(e => {
									this.addAlert(
										`A problem with
					 the database prevented your tree
					  from being saved: ${e.message}`,
										"dashboard"
									);
								});
							}

							history.push(path);
						}
					);
				}
			);
		} else {
			history.push(path);
		}
	};

	deleteProject = (uuid) => {
		let projects = [...this.state.projects];
		let project = {...this.state.project};

		projects = projects.filter(project => project.uuid !== uuid);

		if (project.uuid === uuid) project = new Project();

		this.setState({
				projects: projects,
				project: project,
			},
			() => {
				if (this.database.available) {
					this.database.database.delete(
						"projects", uuid
					).then(() => {
						// if (projects.length <= 0) this.getAllProjects();
					}).catch(e => {
						this.addAlert(
							`A problem with the
				 database prevent this tree from being
				  deleted: ${e.message} Refreshing
				   the page may resolve the issue.`,
							"dashboard"
						);
					});
				}
			}
		)
	};

	retrieveProjects = () => {
		let db_name = "43cjbdoq74egpxye";
		let open_request = indexedDB.open(db_name);
		let {database} = {...this};

		open_request.onerror = (event) => {
			database.available = false;
			database.checked = true;
			database.database = null;

			this.database = database;
		};

		open_request.onsuccess = (event) => {
			database.available = true;
			database.checked = true;
			database.database = new Database(database.params);

			let projects = [];
			this.database.database.getAll(
				"projects"
			).then((retrieved) => {
				if (retrieved) {
					retrieved.forEach((project, index) => {
						if (
							project.uuid === this.state.project.uuid &&
							this.state.project.editing
						) {
							project.editing = true;
						}
						projects.push(new Project(project));
					});

					this.setState({
							projects: projects,
						},
						() => this.database = database
					);
				}
			}).catch(e => {
				this.addAlert(
					`We were unable to retrieve any
				 trees from the database: ${e.message}.`,
					"dashboard"
				);
			});
		};
	};

	retrieveProject = (uuid, callback) => {
		let projects = [...this.state.projects];

		projects.forEach((project, idx) => {
			if (project.uuid === uuid) {
				project.editing = true;
				projects[idx] = project;

				this.setState({
						projects: projects,
						project: project,
					},
					() => {
						if (callback) callback();
					}
				)
			}
		});
	};

	exportProjects = () => {
		if (this.state.projects.length) {
			let filename = `MyTree_${Date.now()}.csv`;
			let projects = [...this.state.projects];
			let fields = [];

			projects.forEach((project, idx) => {
				fields.push(project.__flatten());
			});

			csvExport(fields, filename);
		}
	};

	importProjects = async (file) => {
		let projects = [...this.state.projects];
		let results = await csvImport(file);
		let data = results.data;

		if (data.length) {
			for (let i = 0; i < data.length; i++) {
				let unique = true;
				let datum = data[i];

				for (let j = 0; j < projects.length; j++) {
					if (projects[j].uuid === datum.project_uuid) unique = false;
				}

				if (unique) {
					let project = new Project();
					project.__rebuild(datum);
					await project.benefits.getBenefits(project);
					projects.push(project);
				}
			}

			this.setState({
					projects: projects,
				},
				() => {
					if (this.database.available) {
						this.database.database.bulkSave(
							"projects",
							projects
						);
					}
				}
			)
		}
	};

	updateUnitType = () => {
		this.setState((prevState) => ({
			unitType: !prevState.unitType
		}));
	};

	updateSortingType = (event) => {
		this.setState({
			sortSpeciesAlphabetically: event.currentTarget.value === "true",
		});
	};

	updateCurrency = (currencyLocale) => {
		this.setState({
			currencyLocale: currencyLocale
		});
	};

	updateProject = (data = null, callback = null) => {
		let project = JSON.parse(JSON.stringify(this.state.project));

		Object.keys(data).forEach((projectKey, index) => {
			let update = data[projectKey];

			switch (projectKey) {
				case "project":
					Object.keys(update).forEach((projectSubKey, index) => {
						project[projectSubKey] = update[projectSubKey];
					});
					break;
				case "location":
					let location = {...project.location};
					Object.keys(update).forEach((locationKey, index) => {
						if (locationKey === "address") {
							Object.keys(update[locationKey]).forEach((addresKey, index) => {
								location.address[addresKey] = update[locationKey][addresKey];
							});
						} else {
							location[locationKey] = update[locationKey];
						}
					});
					project.location = location;
					break;
				case "tree":
					let tree = {...project.tree};
					Object.keys(update).forEach((treeKey, index) => {
						tree[treeKey] = update[treeKey];
					});
					project.tree = tree;
					break;
				case "building":
					let building = {...project.building};
					Object.keys(update).forEach((buildingKey, index) => {
						building[buildingKey] = update[buildingKey];
					});
					project.building = building;
					break;
				case "hthc":
					let hthc = {...project.hthc};
					Object.keys(update).forEach((hthcKey, index) => {
						hthc[hthcKey] = update[hthcKey];
					});
					project.hthc = hthc;
					break;
				default:
					console.log("DEFAULT SWITCH STATEMENT");
			}
		});

		project = new Project(project);

		this.setState({
				project,
			},
			() => {
				this.store.putInStorage(this.state.project);
				if (callback) callback();
			}
		);
	};

	addAlert = (content, category, type = "error") => {
		let alerts = {...this.state.alerts};

		if (content) {
			if (alerts[category] && alerts[category].length >= 1) {
				for (let alert in alerts[category]) {
					if (alerts[category][alert].content !== content) {
						alerts[category].push(
							{
								content: content,
								type: type
							}
						);
					}
				}
			} else {
				alerts[category].push(
					{
						content: content,
						type: type
					}
				);
			}
		}

		this.setState({
			alerts: alerts
		})
	};

	setMessage = (title = "", content = "", visible = false) => {
		this.setState({
			message: {
				title: title,
				content: content,
				visible: visible
			}
		})
	};

	reset = () => {
		let {database} = {...this};

		if (database.available) {
			database.database.deleteDatabase().then(() => {
				database.database = new Database(database.params);
				this.database = database;
			}).catch(e => {
				this.addAlert(
					`${e.message}: The database
				 could not be deleted.`,
					"dashboard"
				)
			});
		}

		this.setState({
			project: new Project(),
			unitType: true,
			projects: [],
			alerts: {
				dashboard: [],
				location: [],
				tree: [],
				building: [],
				results: []
			},
			messages: {
				title: "",
				content: ""
			},
		});

		this.store.removeFromStorage();
	};

	render() {
		let menus = [
			<ProjectDropdown
				key={"projectDropdown"}
				unitType={this.state.unitType}
				sortSpeciesAlphabetically={this.state.sortSpeciesAlphabetically}
				currencyLocale={this.state.currencyLocale}
				updateUnitType={this.updateUnitType}
				updateSortingType={this.updateSortingType}
				updateCurrency={this.updateCurrency}
				reset={this.reset}
				exportProjects={this.exportProjects}
				importProjects={this.importProjects}
			/>,
			<MenuDropdown key={"menuDropdown"}/>,
		];

		let code = LANGUAGES_AND_CURRENCY_CODES[this.state.currencyLocale];
		let currency = {
			code: code,
			locale: this.state.currencyLocale,
			exchangeRate: this.exchangeRates[code],
		};

		return (
			<div id={"navigation"}>
				<SharedNavbar
					{...PROPS.NAVBAR}
					menus={menus}
					afterBrand={AfterBrand}
					afterCollapse={AfterCollapse}
				/>
				<DevelopmentWarning/>
				{this.state.processing ?
					<ProcessingModal
						isOpen={this.state.processing}
					/>
					:
					null
				}
				<div id={"content"}>
					<Switch>
						<Route
							path={"/"}
							exact
							render={
								props =>
									<React.Fragment>
										<Pages.Landing
											{...props}
											{...PROPS.LANDING}
											projectCount={this.state.projects.length}
											initializeProject={this.initializeProject}
											reset={this.reset}
										/>
									</React.Fragment>
							}
						/>
						<Route
							path={"/dashboard"}
							exact
							render={
								props =>
									<React.Fragment>
										<Alerts alerts={this.state.alerts.dashboard}/>
										<Message
											message={this.state.message}
											setMessage={this.setMessage}
										/>
										<Pages.Dashboard
											{...props}
											pin={this.state.project.location.pin}
											projects={this.state.projects}
											unitType={this.state.unitType}
											deleteProject={this.deleteProject}
											getAllProjects={this.getAllProjects}
											initializeProject={this.initializeProject}
											retrieveProject={this.retrieveProject}
											updateUnitType={this.updateUnitType}
											reset={this.reset}
											addAlert={this.addAlert}
											setMessage={this.setMessage}
										/>
									</React.Fragment>
							}
						/>
						<Route
							path={"/location"}
							exact
							render={
								props =>
									<React.Fragment>
										<Alerts alerts={this.state.alerts.location}/>
										<Message
											message={this.state.message}
											setMessage={this.setMessage}
										/>
										<Pages.LocationWrapper
											{...props}
											{...PROPS.LOCATION}
											location={this.state.project.location}
											updateProject={this.updateProject}
										/>
									</React.Fragment>
							}
						/>
						<Route
							path={"/tree"}
							render={
								props =>
									<React.Fragment>
										<Pages.TreeForm
											{...props}
											sortSpeciesAlphabetically={this.state.sortSpeciesAlphabetically}
											unitType={this.state.unitType}
											project={this.state.project}
											updateProject={this.updateProject}
											saveProject={this.saveProject}
										/>
									</React.Fragment>
							}
						/>
						<Route
							path={"/hthc"}
							render={
								props =>
									<React.Fragment>
										<Pages.HTHC
											hthc={this.state.project.hthc}
											updateProject={this.updateProject}
											saveProject={this.saveProject}
										/>
									</React.Fragment>
							}
						/>
						<Route
							path={
								[
									"/benefits/individual",
									"/report/individual",
									"/benefits/:project/:location/:tree/:building/:hthc",
									"/report/:project/:location/:tree/:building/:hthc",
								]
							}
							render={
								props =>
									<React.Fragment>
										<Alerts alerts={this.state.alerts.results}/>
										<Message
											message={this.state.message}
											setMessage={this.setMessage}
										/>
										<Pages.IndividualBenefits
											{...props}
											project={this.state.project}
											unitType={this.state.unitType}
											currency={currency}
											addAlert={this.addAlert}
										/>
									</React.Fragment>
							}
						/>
						<Route
							path={
								[
									"/benefits/total",
									"/report/total",
								]
							}
							render={
								props =>
									<React.Fragment>
										<Alerts alerts={this.state.alerts.results}/>
										<Message
											message={this.state.message}
											setMessage={this.setMessage}
										/>
										<Pages.TotalBenefits
											{...props}
											projects={this.state.projects}
											unitType={this.state.unitType}
											currency={currency}
											addAlert={this.addAlert}
											retrieveProjects={this.retrieveProjects}
										/>
									</React.Fragment>
							}
						/>
						<Route path={"/about"} component={Pages.Info}/>
						<Route
							render={
								props =>
									<Pages.GenericNotFound {...props}/>
							}
						/>
					</Switch>
				</div>
			</div>
		);
	}
}
