import React from 'react';

import {
	FormGroup,
	Input,
	Label,
} from "reactstrap";


export const SortingToggle = (
	{
		checked,
		onChange,
	}
) => {
	return (
		<React.Fragment>
			<div className={"mx-3 text-nowrap"}>
				Toggle between sorting methods.
			</div>
			<div className={"mx-3"}>
				<FormGroup tag="fieldset">
					<FormGroup check>
						<Label check>
							<Input
								type="radio"
								name="sortingType"
								checked={checked === true}
								value={"true"}
								onChange={onChange}
							/>
							Alphabetical
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label check>
							<Input
								type="radio"
								name="sortingType"
								checked={checked === false}
								value={"false"}
								onChange={onChange}
							/>
							Name Similarity
						</Label>
					</FormGroup>
				</FormGroup>
			</div>
		</React.Fragment>
	)
}
