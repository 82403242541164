import React, {useEffect, useState} from 'react';

import {useLocation} from 'react-router-dom';

import {
	Container,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";

import {
	NavButton,
	InfoModalButton
} from "../../parts/Buttons";

import {
	Benefits,
	Census,
	Story,
	Scorecard,
	ReportMaps,
} from "../../parts/OurTrees";

import {
	Bottom,
	HeavyLine,
} from "../../parts/NutritionLabelParts";

import {
	ErrorModal,
	ProcessingModal
} from "../../parts/Modals";

import "../../../stylesheets/nutrition.scss";

import {
	DEVMODE,
	MYTREE,
} from "../../../utilities/resources";
import {REPORT} from "../../static_props/OurTrees";
import {SocialSharing} from "../../SocialSharing/SocialSharing";
import {assembleExclusionList} from "../../parts/OurTrees/reportHelper";
import {toNameCase} from "../../../utilities/utilities";
import html2canvas from "html2canvas";
import {ChildIterator} from "../../parts/shared";


export const Report = (
	{
		activeBoundaryLayerIndex,
		geoid,
		history,
		unitType,
	}
) => {
	let [state, setState] = useState(
		{
			activeTab: 0,
			address: "",
			canvasTarget: "benefits-canvas",
			canopyData: {
				original: null,
				improved: null,
			},
			comparisonSubdivisions: [],
			comparisonPlaces: [],
			error: false,
			errorMessage: "",
			processing: true,
		}
	);
	let navLocation = useLocation();

	useEffect(() => {
			let handleBenefitFetching = async () => {
				let originalCanopyResponse = await fetchBenefits();
				let canopyChange = 10; // This represents a 10% increase in canopy cover.
				let improvedCanopyResponse = await fetchBenefits(canopyChange);

				if (originalCanopyResponse.ok === false) {
					setState(prev => ({
						...prev,
						canopyData: {
							original: null,
							improved: null,
						},
						error: true,
						errorMessage: `Status ${originalCanopyResponse.status}: ${originalCanopyResponse.statusText}.`,
						processing: false,
					}));
				} else if (improvedCanopyResponse.ok === false) {
					setState(prev => ({
						...prev,
						canopyData: {
							original: null,
							improved: null,
						},
						error: true,
						errorMessage: `Status ${improvedCanopyResponse.status}: ${improvedCanopyResponse.statusText}.`,
						processing: false,
					}));
				} else {
					if (
						!originalCanopyResponse ||
						!improvedCanopyResponse ||
						typeof originalCanopyResponse === "undefined" ||
						typeof improvedCanopyResponse === "undefined"
					) {
						setState(prev => ({
							...prev,
							processing: false,
							error: true,
							errorMessage: `The data could not be retrieved. Please try again.`,
						}));
					} else {
						let originalCanopyJson = await originalCanopyResponse.json();
						let originData = new Map(Object.entries(originalCanopyJson.data));

						let improvedCanopyJson = await improvedCanopyResponse.json();
						let improvedData = new Map(Object.entries(improvedCanopyJson.data));

						setState(prev => ({
							...prev,
							address: originalCanopyJson.address,
							comparisonSubdivisions: originalCanopyJson.comparison_subdivisions,
							comparisonPlaces: originalCanopyJson.comparison_places,
							canopyData: {
								original: originData,
								improved: improvedData,
							},
							error: false,
							errorMessage: "",
							processing: false,
						}));
					}
				}
			};
			handleBenefitFetching();
		},
		[]
	);

	useEffect(() => {
		let searchParams = new URLSearchParams(navLocation.search);
		let tabFromSearchParams = searchParams.get("tab");
		tabFromSearchParams = `${tabFromSearchParams.substring(0,1).toUpperCase()}${tabFromSearchParams.slice(1)}`;
		let indexOfTab = tabs.indexOf(tabFromSearchParams);
		if (state.activeTab !== indexOfTab) {
			setState(prev => ({
				...prev,
				activeTab: indexOfTab,
			}));
		}
	},
		[navLocation.search]
	);

	let fetchBenefits = async (canopyChange = null) => {
		let controller = new AbortController();
		let signal = controller.signal;
		let time = 1000 * 60 * 30; // 30 minutes
		let timeout = setTimeout(() => controller.abort("Server timed out"), time);
		let url;
		let origin = `https://${DEVMODE ? "dev." : ""}landscape.itreetools.org`;
		let path = "/api/ourtrees/";

		let searchParams = new URLSearchParams(
			window.location.toString().substring(
				window.location.toString().indexOf("?")
			)
		);

		searchParams.append(
			"boundary",
			activeBoundaryLayerIndex === 0 ? "place" : "subdivisions"
		);

		searchParams.append(
			"exclusions",
			assembleExclusionList().toString()
		);

		if (geoid) searchParams.append("geoid", geoid);
		if (canopyChange) searchParams.append("canopy-change", canopyChange);

		// if (window.location.hostname === "localhost") {
		// 	url = `${path}?${searchParams}`;
		// } else {
			url = new URL(`${path}?${searchParams}`, origin);
		// }

		signal.addEventListener("abort", () => {
			setState(prev => ({
				...prev,
				processing: false,
				error: true,
				errorMessage: signal.reason,
			}));
			clearTimeout(timeout);
			controller = null;
		});

		let response = await fetch(
			url,
			{
				signal: signal,
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json',
				},
			}
		);

		if (response.ok) {
			clearTimeout(timeout);
			controller = null;
		}

		return response;
	};

	let changeTab = (tab) => {
		let locationString = window.location.toString().replace("#/", "");
		let url = new URL(locationString);
		url.searchParams.set("tab", tabs[tab].toLowerCase());
		history.push(`${url.pathname}?${url.searchParams.toString()}`);
	};

	let modalText = <span>Estimating<br/>Canopy Benefits...</span>;
	let body = "";
	let emailSubject = "Check out this OurTrees report!";
	let emailURL = window.location.toString();
	let activeTabClasses = "active border border-primary";
	let tabs = [
		"Benefits",
		"Story",
		"Community",
		// "Equity",
		"Scorecard",
	];
	let canvasTargets = [...tabs.map((tab) => tab.toLowerCase())];

	if (
		!state.processing &&
		!state.error
	) {
		body = `This is an OurTrees report from ${state.address}.`;
	}

	let handleImageExport = async () => {
		let canvasTarget = canvasTargets[state.activeTab];
		let fileName = `OurTrees_${toNameCase(canvasTargets[state.activeTab])}_${state.address.substring(0, state.address.indexOf(","))}.png`;
		let finePrint = document.getElementById("fine-print-collapse");
		let finePrintWasOpen = finePrint.classList.contains("show");
		if (!finePrintWasOpen) finePrint.classList.add("show");

		let communityAccordions = document.getElementById("community").querySelectorAll(".collapse");
		let expandedCommunityAccordions = [];
		communityAccordions.forEach((accordion) => {
			expandedCommunityAccordions.push(accordion.classList.contains("show"));
			if (!accordion.classList.contains("show")) {
				accordion.classList.add("show");
			}
		});

		let reportElement = document.getElementById(canvasTarget);
		const COMMON_OPTIONS = {x: -10, y: -10, scale: 3};

		const REPORT_CANVAS_OPTIONS = {
			width: reportElement.clientWidth + 20,
			height: reportElement.clientHeight + 20,
			...COMMON_OPTIONS,
		};
		let containerCanvas = document.createElement("canvas");
		let reportCanvas = await html2canvas(
			reportElement,
			REPORT_CANVAS_OPTIONS
		);
		containerCanvas.width = reportCanvas.width;
		containerCanvas.height = reportCanvas.height;
		let containerContext = containerCanvas.getContext("2d");
		containerContext.drawImage(reportCanvas, 0, 0);

		if (!MYTREE) {
			let footerElement = document.getElementById("footer");
			const FOOTER_CANVAS_OPTIONS = {
				width: reportCanvas.width + 20,
				height: footerElement.clientHeight + 20,
				...COMMON_OPTIONS,
			};
			let footerCanvas = await html2canvas(
				footerElement,
				FOOTER_CANVAS_OPTIONS
			);
			containerCanvas.height = reportCanvas.height + footerCanvas.height;
			containerContext.drawImage(reportCanvas, 0, 0);
			containerContext.drawImage(footerCanvas, 0, reportCanvas.height);
		}

		let downloadLink = document.createElement("a");
		downloadLink.setAttribute("download", fileName);
		let type = "image/png"; // This is the default "type parameter" of canvas.toBlob
		let quality = 1; // A Number between 0 and 1 indicating the image quality to be used when creating images using file formats that support lossy compression.
		containerCanvas.toBlob(
			(blob) => {
				let url = URL.createObjectURL(blob);
				downloadLink.setAttribute("href", url);
				downloadLink.click();
			},
			type,
			quality
		);

		if (!finePrintWasOpen) finePrint.classList.remove("show");

		expandedCommunityAccordions.forEach((expanded, index) => {
			if (!expanded) communityAccordions[index].classList.remove("show");
		});
	};

	return (
		<Container>
			{state.processing ?
				<ProcessingModal
					isOpen={state.processing}
					text={modalText}
				/>
				:
				null
			}
			<Row className={"mt-3"}>
				<Col>
					<div className={"d-flex justify-content-around"}>
						<NavButton
							block={false}
							className={"round"}
							color={"success"}
							leftIcon={"arrow-left"}
							path={"/location"}
							text={"Return to location"}
						/>
						<InfoModalButton
							subject={"resultsPage"}
						/>
					</div>
				</Col>
			</Row>
			{state.error ?
				<ErrorModal
					history={history}
					isOpen={state.error}
					message={state.errorMessage}
				/>
				:
				<Row>
					<Col xl={{size: 6, offset: 3}}>
						<Nav
							className={"flex-column flex-sm-row mx-auto mt-3"}
							justified
							tabs
						>
							{tabs.map((tab, index) => {
								return (
									<NavItem key={tab}>
										<NavLink
											role={"button"}
											className={state.activeTab === index ? activeTabClasses : null}
											onClick={() => changeTab(index)}
										>
											{tab}
										</NavLink>
									</NavItem>
								)
							})}
						</Nav>
						{state.processing ?
							null
							:
							<Row
								id={"canvas-target"}
								className={"pt-3 nutrition"}
							>
								<Col>
									<TabContent activeTab={state.activeTab}>
										<TabPane tabId={0}>
											<Benefits
												address={state.address}
												benefits={state.canopyData.original}
												canvasTarget={canvasTargets[0]}
												imperial={unitType}
											/>
										</TabPane>
										<TabPane tabId={1}>
											<Story
												address={state.address}
												benefits={state.canopyData.original}
												canvasTarget={canvasTargets[1]}
												imperial={unitType}
											/>
										</TabPane>
										<TabPane tabId={2}>
											<Census
												address={state.address}
												benefits={state.canopyData.original}
												canvasTarget={canvasTargets[2]}
											/>
										</TabPane>
										{/*TODO: Restore this tab (and tabId) when we can display climate change stuff again.*/}
										{/*<TabPane tabId={3}>*/}
										{/*	<ReportMaps*/}
										{/*		address={state.address}*/}
										{/*		layer={activeBoundaryLayerIndex === 0 ? "places" : "subdivision"}*/}
										{/*		canvasTarget={canvasTargets[3]}*/}
										{/*	/>*/}
										{/*</TabPane>*/}
										<TabPane tabId={3}>
											<Scorecard
												activeBoundaryLayerIndex={activeBoundaryLayerIndex}
												address={state.address}
												canvasTarget={canvasTargets[3]}
												comparisonSubdivisions={state.comparisonSubdivisions}
												comparisonPlaces={state.comparisonPlaces}
												imperial={unitType}
												improvedFeature={state.canopyData.improved}
												originalFeature={state.canopyData.original}
											/>
										</TabPane>
									</TabContent>
									<ChildIterator>
										<HeavyLine/>
										<Bottom {...REPORT} />
										<SocialSharing
											body={body}
											handleImageExport={handleImageExport}
											url={emailURL}
											emailSubject={emailSubject}
										/>
									</ChildIterator>
								</Col>
							</Row>
						}
					</Col>
				</Row>
			}
		</Container>
	);
}
