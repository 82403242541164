import React, {PureComponent} from 'react';

import {NavLink as RRNavLink, withRouter} from "react-router-dom";

import {
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	NavLink,
	UncontrolledDropdown,
} from "reactstrap";

import {
	ResetModal,
	ImportModal,
} from "../../Modals";

import {
	CurrencySelector,
	UnitToggle,
} from "../Shared";

import {SortingToggle} from "./SortingToggle";

import {DEVMODE} from "../../../../utilities/resources";


class ProjectDropdown extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			navbarIsOpen: false,
			resetIsOpen: false,
			importisOpen: false,
			selectedOption: 0
		};
	}

	toggle = () => {
		this.setState((prevState) => ({
			navbarIsOpen: !prevState.navbarIsOpen
		}));
	};

	toggleResetModal = () => {
		this.setState({
			resetIsOpen: !this.state.resetIsOpen
		});
	};

	toggleImportModal = () => {
		this.setState({
			importIsOpen: !this.state.importIsOpen
		});
	};

	reset = (e) => {
		this.props.reset(e);
		this.toggleResetModal("reset");
		this.props.history.push("/");
	};

	render() {
		let dashboard = "/dashboard";

		return (
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav caret>
					Project
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem>
						<NavLink
							tag={RRNavLink}
							to={dashboard}
						>
							My Tree List
						</NavLink>
					</DropdownItem>
					{DEVMODE ?
						<React.Fragment>
							<DropdownItem
								onClick={
									() => this.props.exportProjects()
								}
							>
								Save
							</DropdownItem>
							<DropdownItem
								onClick={this.toggleImportModal}
							>
								Load
							</DropdownItem>
							<ImportModal
								isOpen={this.state.importIsOpen}
								toggleModal={this.toggleImportModal}
								importProjects={this.props.importProjects}
							/>
						</React.Fragment>
						:
						null
					}
					<DropdownItem
						onClick={this.toggleResetModal}
					>
						Start Over
					</DropdownItem>
					<ResetModal
						isOpen={this.state.resetIsOpen}
						toggleModal={this.toggleResetModal}
						reset={this.reset}
					/>
					<DropdownItem divider/>
					<div className={"mx-3 text-nowrap"}>
						Select a location for currency
						conversion <em>BETA</em>.
					</div>
					<CurrencySelector
						currencyLocale={this.props.currencyLocale}
						updateCurrency={this.props.updateCurrency}
					/>
					<DropdownItem divider/>
					<UnitToggle
						checked={this.props.unitType}
						onChange={this.props.updateUnitType}
					/>
					<DropdownItem divider/>
					<SortingToggle
						checked={this.props.sortSpeciesAlphabetically}
						onChange={this.props.updateSortingType}
					/>
				</DropdownMenu>
			</UncontrolledDropdown>
		)
	}
}

export default withRouter(ProjectDropdown);
