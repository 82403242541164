import React from 'react';


export const RankingList = (
	{
		address,
		comparisons,
		coverages,
	}
) => {
	let clonedComparisons = structuredClone(comparisons);
	let sortedComparisons = [];
	clonedComparisons.forEach((comparison) => {
		let name = "";
		let canopyPercent = 0;
		comparison.forEach((field) => {
			let key = Object.keys(field)[0];
			if (key === "name") {
				name = field[key];
			}
			if (key === "canopy.percent") {
				canopyPercent = parseFloat(field[key]);
			}
		});
		sortedComparisons.push(
			{
				name,
				canopyPercent,
			}
		);
	});
	sortedComparisons = sortedComparisons.sort((a, b) => {
			a = Object.values(a)[1];
			b = Object.values(b)[1];
			return b - a; // sorts to descending order
		}
	);

	let clonedCoverages = structuredClone(coverages);
	let originCanopyPercent = parseFloat(clonedCoverages.get("canopy.percent"));
	let comparisonElements = [];

	sortedComparisons.forEach((comparison, index) => {
		let match = false;
		if (
			address.includes(comparison.name) &&
			comparison.canopyPercent === originCanopyPercent
		) {
			match = true;
		}

		comparisonElements.push(
			<span
				className={match ? "fw-bold" : ""}
				key={`${comparison.name}-${comparison.canopyPercent}`}
			>
				{comparison.name}: {comparison.canopyPercent}%
			</span>
		);
	});

	return (
		<ol className={"mb-0"}>
			{comparisonElements.map((comparison, index) => {
				return (
					<li key={comparison.key}>
						{comparison}
					</li>
				)
			})}
		</ol>
	);
}
