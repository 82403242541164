import React, {Component} from "react";

import house from "../../img/MyTree_House.png";


export class ModalContents extends Component {
	constructor(props) {
		super(props);

		let mytree = "https://help.itreetools.org/mytree";
		let ourtrees = "https://help.itreetools.org/ourtrees";
		let suffix = "?embed=true";

		this.contents = {
			address: {
				header: "Address",
				url: `${mytree}/address${suffix}`,
				body: <p className={"leading"}>The address that you enter tells
					MyTree where your tree is located which in turn helps
					localize your results. Addresses can be entered manually or
					using the geolocation function on your mobile device. If you
					are not satisfied with the address that appears here, click
					the Back button and enter a different address.</p>
			},
			name: {
				header: "Name of Tree",
				url: `${mytree}/name-tree${suffix}`,
				body: <p className={"leading"}>You can give any name to the
					tree. The purpose of having this field is to give you
					an option to name your tree (e.g., big front yard tree),
					so that you can easily find the tree among potentially
					many trees on the dashboard page.</p>
			},
			species: {
				header: "Tree Species",
				url: `${mytree}/tree-species${suffix}`,
				body: <p className={"leading"}>The i-Tree species list has
					9,000+ items that cannot all be listed at once. Please
					type a few letters to narrow down the choices. Example:
					typing “oa” will bring up all the Oak species. Please
					select the species name of the tree that you would like
					to assess. Use the toggle to view species by common or
					scientific name. Choose a species from the drop&ndash;down
					list. For help identifying your tree, check out these
					resources:
					<br/>
					<a
						href="https://plants.usda.gov/"
						target="_blank"
						rel="noopener noreferrer"
					>
						USDA's PLANTS Database
					</a>
					<br/>
					<a
						href="https://www.arborday.org/trees/whattree/mobile/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Arbor Day’s "What Tree Is That"?
					</a>
				</p>
			},
			condition: {
				header: "Tree Condition",
				url: `${mytree}/tree-condition${suffix}`,
				body: <div><p className={"leading"}>Please select the
					condition that best describes your tree by choosing a
					class from the drop&ndash;down list. Here is a tip: Tree
					condition can be identified by looking at the leaves of
					your tree's crown &ndash; are portions of the crown
					missing leaves? The following classes available to
					choose from:
				</p>
					<ul>
						<li>
							Excellent&ndash;Tree has less than 1% of its
							leaves missing.
						</li>
						<li>
							Good&ndash;Tree is missing 1&ndash;10% of its
							leaves.
						</li>
						<li>
							Fair&ndash;Tree is missing 11&ndash;25% of its
							leaves.
						</li>
						<li>
							Poor&ndash;Tree is missing 26&ndash;50% of its
							leaves.
						</li>
						<li>
							Critical&ndash;Tree is missing 51&ndash;75% of
							its leaves.
						</li>
						<li>
							Dying&ndash;Tree is missing 76&ndash;99% of its
							leaves.
						</li>
						<li>
							Dead&ndash;Tree is missing all of its leaves.
						</li>
					</ul>
				</div>
			},
			diameter: {
				header: "Trunk Measurement",
				url: `${mytree}/trunk-measurement${suffix}`,
				body: <div className={"leading"}>
					<p>Trunk measurement is the size of your tree’s trunk
					measured at about 4.5 feet (1.37 meters) above the ground.
					This can be entered as diameter or circumference, and be
					in inches (in) or centimeters (cm). Enter the trunk size of
					your tree in the provided field, and use the toggle to
					indicate whether this is the diameter or circumference of
					the trunk.</p>
					<p><strong>Note:</strong> The unit is shown in
					parentheses.</p>
					<p><strong>Reminder:</strong> Picking a unit system of
					English or Metric is configured under the Project menu.</p>
					<p>Due to limitations of the available models,
					i-Tree limits carbon storage to a maximum of 7,500 kg
					(~16,535 lbs) and does not estimate additional storage for
					any tree beyond a diameter of 254 cm (100 in). Whichever
					limit results in lower carbon storage is used.</p>
				</div>
			},
			exposure: {
				header: "Sun Exposure",
				url: `${mytree}/sun-exposure${suffix}`,
				body: <p className={"leading"}>Sun exposure is the amount
					of sun that reaches the leaves of the tree based on its
					surroundings (i.e., the presence of additional
					structures or trees that may shade the tree). Select the
					sun exposure that best describes your tree by choosing
					from the drop&ndash;down list.
				</p>
			},
			proximity: {
				header: "Nearby Building",
				url: `${mytree}/nearby-building${suffix}`,
				body: <p className={"leading"}>Trees that are located in
					close proximity to buildings can affect the amount of
					energy used to heat or cool that building by modifying
					the climate, producing shade, and reducing wind speeds.
					Building vintage, distance to building, and direction
					from tree to building is used to estimate how much the
					tree affects the amount of energy used to heat or cool
					that building.
				</p>
			},
			vintage: {
				header: "Building Vintage",
				url: `${mytree}/building-vintage${suffix}`,
				body: <p className={"leading"}>Building vintage, or the year
					the building was constructed, gives MyTree information
					about the energy efficiency of your nearby building.
				</p>
			},
			distance: {
				header: "Distance to Building",
				url: `${mytree}/distance-building${suffix}`,
				body: <p className={"leading"}>Please select the distance of
					your tree to the closest part of the nearby building by
					choosing from the drop&ndash;down list.</p>
			},
			direction: {
				header: "Direction from Tree to Building",
				url: `${mytree}/direction-tree-building${suffix}`,
				body: <div>
					<p className={"leading"}>Please select the
						direction from the tree to the closest part of the
						building by choosing the option from the drop&ndash;down
						list that is the most appropriate.
					</p>
					<img
						alt={"MyTree House"}
						src={house}
						width="300"
					/>
					<p className='leading'>Tips:</p>
					<p className='leading'>Most mobile devices have a
						compass app that can help. Stand at the tree, point
						the mobile device toward the closest part of the
						building, choose the direction in the MyTree
						drop&ndash;down list that is the most similar to the
						one on the compass.
					</p>
					<p className='leading'>If you do not have that option,
						and know how roughly where the sun rises and/or
						sets, then you know which direction is east and/or
						west. You can then stand at the tree, face the
						closest part of the building, and choose the
						appropriate option from the drop&ndash;down list.
						For example, if you are standing at the tree facing
						the closest part of the building, and you know the
						sun rises directly left, then the direction from
						tree to building is south.
					</p>
				</div>
			},
			health: {
				header: "Tree Health",
				url: `${mytree}/tree-health${suffix}`,
				body: null,
			},
			pests: {
				header: "Tree Pests",
				url: `${mytree}/pest-symptoms${suffix}`,
				body: null,
			},
			delete: {
				header: "Delete All Trees",
				url: `${mytree}/delete-all-trees${suffix}`,
				body: null,
			},
			reset: {
				header: "Start Over",
				url: `${mytree}/start-over${suffix}`,
				body: null,
			},
			whyMyTree: {
				header: "Why MyTree",
				url: `${mytree}/why-mytree${suffix}`,
				body: <div>
					{/*TODO: Restore to this paragraph once we can display "climate change" again.*/}
					{/*<p className={"leading"}>*/}
					{/*	Offering more than just beauty and shade, trees*/}
					{/*	provide tangible benefits, such as removal of*/}
					{/*	atmospheric carbon dioxide and pollution, storm*/}
					{/*	water reduction, temperature modification, and more.*/}
					{/*	By removing carbon dioxide, trees help mitigate*/}
					{/*	climate change. The shade provided by urban tree*/}
					{/*	canopies can also help minimize the urban heat*/}
					{/*	island effect. In addition, trees intercept*/}
					{/*	storm water, which can reduce flooding and improve*/}
					{/*	water quality, and reduce air pollution, such as*/}
					{/*	ozone, carbon monoxide, sulfur dioxide, nitrogen*/}
					{/*	dioxide, and fine particulate matter. Reduction of*/}
					{/*	air pollution has proven benefits to human health -*/}
					{/*	trees truly can enhance our lives! MyTree allows*/}
					{/*	you to explore the individual benefits provided by*/}
					{/*	the trees near you.*/}
					{/*</p>*/}
					<p className={"leading"}>
						Offering more than just beauty and shade, trees
						provide tangible benefits, such as removal of
						atmospheric carbon dioxide and pollution, storm
						water reduction, temperature modification, and more.
						The shade provided by urban tree
						canopies can also help minimize the urban heat
						island effect. In addition, trees intercept
						storm water, which can reduce flooding and improve
						water quality, and reduce air pollution, such as
						ozone, carbon monoxide, sulfur dioxide, nitrogen
						dioxide, and fine particulate matter. Reduction of
						air pollution has proven benefits to human health -
						trees truly can enhance our lives! MyTree allows
						you to explore the individual benefits provided by
						the trees near you.
					</p>
				</div>
			},
			whyOurTrees: {
				header: "Why OurTrees",
				url: `${ourtrees}/why-ourtrees${suffix}`,
			},
			location: {
				header: "Location",
				url: `${mytree}/location${suffix}`,
			},
			type: {
				header: "Tree Type",
				url: `${mytree}/type-tree${suffix}`,
			},
			group: {
				header: "Optional Note or Label",
				url: `${mytree}/project-name${suffix}`,
			},
			hthc: {
				header: "Healthy Trees, Healthy Cities",
				url: `${mytree}/healthy-trees-healthy-cities${suffix}`,
			},
			trillionTrees: {
				header: "Trillion Trees Campaign",
				url: `${mytree}/trillion-trees${suffix}`,
			},
			factsheetMeaning: {
				header: "Nutrition Label Meaning",
				url: `${mytree}/what-do-these-tree-benefit-estimates-mean${suffix}`,
			},
			resultsPage: {
				header: "What Does All This Mean?",
				url: `${ourtrees}/ourtrees-benefits${suffix}`,
			},
			mapPage: {
				header: "Select Your Community",
				url: `${ourtrees}/select-your-community${suffix}`,
			},
		}
	}

	componentDidMount() {
		window.addEventListener('message', (evt) => {
			this.listener(evt);
		});
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.listener)
	}

	listener = (evt) => {
		let {data: message, origin, source: sender} = evt;
		let url = new URL(origin);

		if (url.host.match(/itreetools.org$/)) {
			if (message instanceof Object) {
				let {event, source, data} = message;
				if (event === 'size') {
					let {height} = data;
					let frames = source
						? document.querySelectorAll(`iframe[name='${source}']`)
						: document.getElementsByTagName('iframe');
					for (let i = 0; i < frames.length; ++i) {
						let frame = frames[i];
						if (frame.contentWindow === sender) {
							frame.height = height;
						}
					}
				}
			}
		}
	};

	render() {
		return (
			<div
				id={`${this.props.subject}-modal`}
				className={"d-flex"}
			>
				<iframe
					name={`${this.props.subject}-iframe`}
					className={"flex-fill m-3 border-0"}
					src={this.contents[this.props.subject]["url"]}
					title={`${this.props.subject}-iframe`}
				/>
			</div>
		)
	}
}
