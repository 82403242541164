import React from 'react';

import {
	Row,
	Col,
} from "reactstrap";

import {Top} from "./Top";
import {RankingList} from "./RankingList";

import {
	convertCoverageAreas,
	toLocaleString,
	calculateAdditionalCarbonStorage,
	calculateAdditionalAvoidedRunoff,
	calculateAdditionalPollutionRemoval,
	extractCoverages, totalFinancialBenefits,
} from "./reportHelper";


export const Scorecard = (
	{
		activeBoundaryLayerIndex,
		address,
		canvasTarget,
		comparisonSubdivisions,
		comparisonPlaces,
		imperial,
		improvedFeature,
		originalFeature,
	}
) => {
	let coverageUnit = imperial ? "acres" : "hectares";
	let hydroUnit = imperial ? "gallons" : "cubic meters";
	let carbonUnit = imperial ? "tons" : "tonnes";
	let pollutionUnit = imperial ? "pounds" : "kilograms";
	let locationType = activeBoundaryLayerIndex === 0 ? "Census Places" : "Subdivisions";

	let clonedOriginalFeature = structuredClone(originalFeature);
	let clonedImprovedFeature = structuredClone(improvedFeature);

	let originalCoverage = convertCoverageAreas(
		extractCoverages(clonedOriginalFeature),
		imperial
	);
	let improvedCoverage = convertCoverageAreas(
		extractCoverages(clonedImprovedFeature),
		imperial
	);
	let canopy = originalCoverage.get("canopy.area");
	let plantableSpace = originalCoverage.get("plantable_space.area");
	let improvedCanopy = improvedCoverage.get("canopy.area");

	let originalFeatureBenefitTotal = totalFinancialBenefits(clonedOriginalFeature);
	let improvedFeatureBenefitTotal = totalFinancialBenefits(clonedImprovedFeature);
	let totalDifference = toLocaleString(improvedFeatureBenefitTotal - originalFeatureBenefitTotal);

	let additionalCarbonStorage = toLocaleString(
		calculateAdditionalCarbonStorage(
			clonedOriginalFeature,
			clonedImprovedFeature,
			canopy,
			improvedCanopy,
			imperial
		)
	);

	let canopyString = toLocaleString(canopy);
	let plantableSpaceString = toLocaleString(plantableSpace)
	let improvedCanopyString = toLocaleString(improvedCanopy);

	let additionalAvoidedRunoff = toLocaleString(
		calculateAdditionalAvoidedRunoff(
			clonedOriginalFeature,
			clonedImprovedFeature,
			imperial
		)
	);
	let additionalPollutionRemoval = toLocaleString(
		calculateAdditionalPollutionRemoval(
			clonedOriginalFeature,
			clonedImprovedFeature,
			imperial
		)
	);

	return (
		<section id={canvasTarget}>
			<Top text={"OurTrees Scorecard"}/>
			<Row>
				<Col>
					<p className={"h5"}>
						Tree Canopy in {address}
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className={"fw-bold mb-0"}>
						Room for Trees:
					</p>
					<ul>
						<li>
							Existing canopy: {canopyString} {coverageUnit}
						</li>
						<li>
							Plantable space: {plantableSpaceString} {coverageUnit}
						</li>
					</ul>
					<p className={"mt-0"}>
						A 10% increase in canopy area, {canopyString}{" "}
						{coverageUnit} to {improvedCanopyString}{" "}
						{coverageUnit}, would add ${totalDifference}{" "}
						in tree benefits to your community.
					</p>
					<p>
						This increase would include the storage of an
						additional {additionalCarbonStorage} {carbonUnit} of
						carbon, {additionalAvoidedRunoff} more {hydroUnit}{" "}
						of avoided storm water runoff, and the removal of an
						extra {additionalPollutionRemoval} {pollutionUnit}{" "}
						of air pollution.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<section>
						<p className={"h5 mb-1"}>
							Canopy comparison with...
						</p>
						<p className={"fw-bold mb-0"}>
							...nearby Census Subdivisions:
						</p>
						<RankingList
							address={address}
							coverages={originalCoverage}
							comparisons={comparisonSubdivisions}
						/>
					</section>
					<section className={"mt-2"}>
						<p className={"fw-bold mb-0"}>
							...nearby Census Places:
						</p>
						<RankingList
							address={address}
							coverages={originalCoverage}
							comparisons={comparisonPlaces}
						/>
						<small className={"d-block mt-1"}>
							Please note that some census subdivisions
							and census places might share names.
						</small>
					</section>
				</Col>
			</Row>
		</section>
	);
}