import React from "react";
import {Col, Container, Row} from "reactstrap"
import {truncNum} from "../../utilities/utilities";
import tub from "../../img/new_bathtub.png";
import car from "../../img/blue_car.png";
import phone from "../../img/smart_phone.png";
import itree from "../../img/iTree_primary_logo_restricted_SMALL.png";
import * as Parts from "./NutritionLabelParts";


export const Equivalents = (
	{
		benefits,
		children,
		id,
		locationForDisplay,
		unitType,
		name,
		common,
		scientific,
	}
) => {
	// Smartphone equivalent
	let massUnit = unitType ? ["oz", 35.27396195] : ["g", 1000];
	// Convert kilograms to ounces or grams
	let co = parseFloat(benefits.air.CORemoved) * massUnit[1];
	let o3 = parseFloat(benefits.air.O3Removed) * massUnit[1];
	let no2 = parseFloat(benefits.air.NO2Removed) * massUnit[1];
	let so2 = parseFloat(benefits.air.SO2Removed) * massUnit[1];
	let pm25 = parseFloat(benefits.air.PM25Removed) * massUnit[1];
	// https://devicetests.com/how-much-does-a-smartphone-weigh
	let averageSmartphoneMass = unitType ? 5.46746 : 155; // oz vs grams
	let sumOfPollutants = co + o3 + no2 + so2 + pm25;
	let equivalentSmartphones = truncNum(
		sumOfPollutants / averageSmartphoneMass,
		"decimal",
		0
	);

	// Bathtub equivalent
	let hydroUnit = unitType ? ["gal", 1] : ["L", 3.78541];
	// RunoffAvoided and Interceptions are cubic meters,
	// multiplied by 264.172 to convert to gallons, and
	// then converted to ounces or liters as needed.
	let avoided = benefits.hydro.RunoffAvoided * 264.172 * hydroUnit[1];
	let intercepted = benefits.hydro.Interception * 264.172 * hydroUnit[1];
	let averageBathtubVolume = unitType ? 30 : 113.5623535;
	let equivalentBathtubs = truncNum(
		(avoided + intercepted) / averageBathtubVolume,
		"decimal",
		0
	);

	// Per mile car pollution
	// Reference: https://nepis.epa.gov/Exe/ZyNET.exe/P100JPPH.TXT?ZyActionD=ZyDocument&Client=EPA&Index=2011+Thru+2015&Docs=&Query=&Time=&EndTime=&SearchMethod=1&TocRestrict=n&Toc=&TocEntry=&QField=&QFieldYear=&QFieldMonth=&QFieldDay=&IntQFieldOp=0&ExtQFieldOp=0&XmlQuery=&File=D%3A%5Czyfiles%5CIndex%20Data%5C11thru15%5CTxt%5C00000011%5CP100JPPH.txt&User=ANONYMOUS&Password=anonymous&SortMethod=h%7C-&MaximumDocuments=1&FuzzyDegree=0&ImageQuality=r75g8/r75g8/x150y150g16/i425&Display=hpfr&DefSeekPage=x&SearchBack=ZyActionL&Back=ZyActionS&BackDesc=Results%20page&MaximumPages=1&ZyEntry=1&SeekPage=x&ZyPURL
	// 411 grams of CO2 are emitted by the average passenger vehicle per mile.
	let gramsCO2PerMile = 411;
	let equivalentCarPollution = truncNum(
		(benefits.co2.CO2Sequestered * 1000) / gramsCO2PerMile,
		"decimal",
		0
	);

	return (
		<Container
			className={"py-3"}
			id={id}
		>
			<Row>
				<Col className={"d-flex justify-content-between"}>
					{children.label}
					<img
						className={"small-image"}
						src={itree}
						alt="i-Tree Logo"
					/>
				</Col>
			</Row>
			{locationForDisplay ?
				<Parts.Location
					location={locationForDisplay}
				/>
				:
				null
			}
			{common ?
				<Parts.TreeName
					name={name}
					common={common}
					scientific={scientific}
				/>
				:
				null
			}
			<Row className={"mt-n3"}>
				<Col>
					<hr/>
				</Col>
			</Row>
			<Row>
				<Col>
					{children.intro}
				</Col>
			</Row>
			<Row className={"mt-4"}>
				<Col>
					<p className={"h6 fw-bold text-center green"}>
						offset {equivalentCarPollution} mile{equivalentCarPollution == 1 ? "" : "s"} worth
						of CO<sub>2</sub><br/>emitted from the average<br/>gas-powered
						passenger
						vehicle,
					</p>
					<img
						className={"d-block mx-auto"}
						height={56}
						src={car}
						alt={"Car"}
					/>
				</Col>
			</Row>
			<Row className={"mt-4"}>
				<Col>
					<p className={"h6 fw-bold text-center blue"}>
						absorb enough stormwater<br/> to
						fill {equivalentBathtubs} bathtub{equivalentBathtubs == 1 ? "" : "s"},
					</p>
					<img
						className={"d-block mx-auto"}
						height={76}
						src={tub}
						alt={"Bathtub"}/>
				</Col>
			</Row>
			<Row className={"my-4"}>
				<Col>
					<p className={"h6 fw-bold text-center brown"}>
						and remove an amount of pollution<br/>from the
						air &ndash; in gaseous and particulate form &ndash;
						equivalent in<br/> weight
						to {equivalentSmartphones}{" "}
						smartphone{equivalentSmartphones == 1 ? "" : "s"}!
					</p>
					<img
						className={"d-block mx-auto"}
						height={76}
						src={phone}
						alt={"Smart phone"}
					/>
				</Col>
			</Row>
		</Container>
	)
}
