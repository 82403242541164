import React from 'react';


let app = "MyTree";
let rel = "noopener noreferrer";
let target = "_blank";

export const URLS = {
	home: "https://www.itreetools.org/",
	viable: "https://database.itreetools.org/#/viableLocations",
	email: "mailto:info@itreetools.org?subject=Intl Funding",
	eula: "https://help.itreetools.org/eula?embed=true",
	ourtrees: "https://ourtrees.itreetools.org",
	mytree: "https://mytree.itreetools.org",
	forestService: "https://www.fs.usda.gov/",
	iTreeTools: "https://www.itreetools.org/",
};

export const ANCHORS = {
	home: <a href={URLS.home} rel={rel} target={target}>www.itreetools.org</a>,
	eula: <a href={URLS.eula} rel={rel} target={target}>EULA</a>,
	viability: <a href={URLS.viable} rel={rel} target={target}>supported by i-Tree<sup>&reg;</sup></a>,
	ourtrees: <a href={URLS.ourtrees} rel={rel} target={target}>OurTrees</a>,
	email: <a href={URLS.email}>Contact us</a>,
};

export const NAVBAR = {
	app: app,
	tagline:
		<span>
			<span className={"d-none d-lg-inline"}>A tool for assessing individual trees.</span>
			<span className={"d-none d-md-inline d-lg-none"}>For assessing individual trees.</span>
			<span className={"d-none d-sm-inline d-md-none"}>For assessing trees.</span>
			<span className={"d-inline d-sm-none"}>Individual trees.</span>
		</span>,
	taglineClasses: "tagline",
	appNameClasses: "app-name dark-blue",
	navbarClasses: "green-gradient",
};

export const LANDING = {
	app: app,
	headline: "Discover the benefits of your trees!",
	intro:
		<span>
			Tell us about your tree and we'll estimate the{" "}
			<span className={'fw-bold green'}>
				carbon dioxide
			</span>
			{" "}and{" "}
			<span className={'fw-bold brown'}>
				air pollution
			</span>
			{" "}it removes plus{" "}
			<span className={'fw-bold blue'}>
				stormwater
			</span>
			{" "}impacts.
		</span>,
	description: <small className={"fst-italic"}>
					MyTree is a tool for assessing individual trees.<br/>
					Check out {ANCHORS.ourtrees} for canopy cover benefits.
				</small>,
	locationWarning: <small className={"fst-italic"}>
						In locations not yet{" "}
						{ANCHORS.viability},<br/>
						US national averages will be used to<br/>
						estimate tree benefits.<br/>
						{ANCHORS.email} and let's explore funding opportunities<br/>
						to enable it for your country!
					</small>,
	eula: <small className={"fst-italic"}>
			Use of this tool indicates you accept our{" "}
			{/*FIXME: Move the EULA to modal*/}
			{ANCHORS.eula}
		</small>
};


export const LOCATION = {
	headline: "Where is your tree?",
	navButtonParameters: {
		path: "/tree",
		text: "Next, describe your tree",
	},
	subAddressbarText: "Fine-tune the location of your tree by tapping a spot on the map below.",
};

export const REPORT = {
	headline: "MyTree Benefits",
	subheading: ["For this year.", "Over 20 years."],
	total: [
		"Estimated i-Tree benefits this year:",
		<span>Expected i-Tree benefits<br/>over 20 years:</span>,
	],
	upperBar: ["Annual values:", null],
	lowerBar: ["Not an annual value:", null],
};
